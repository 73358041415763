<template>
  <div class="full-screen ltr-type">
      <div class="top-zone">
          <div class="tv zone1">Zone 1</div>
      </div>
      <div class="tv zone4">
          Zone 2
      </div>
  </div>
</template>

<script>

export default {
  name: 'Tv2'
}
</script>

<style lang="scss" scoped>
.top-zone {
    height: 80%;
    display: flex;
    .zone1 {
        width: 100%;
        background-color: #fcb340;
    }
}

.zone4 {
    width: 100%;
    height: 20%;
    background-color: #f93959;
}
</style>